import React from 'react'
import Layout from '../hoc/Layout/Layout'
import SignIn from '../components/Pages/SignIn/SignIn'
import SEO from '../components/seo'

export default () => {
  return (
    <Layout>
      <SEO
        title="PMP Preparation | Sign In | PMP Exam Simulator"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description="Sign In for PMP Exam Simulator. PMP Training with PMP Mock Exam, Performance Chart and Statistics."
      ></SEO>
      <SignIn />
    </Layout>
  )
}
