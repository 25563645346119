import React, { Component } from 'react'
import { updateObject, checkValidity } from '../../../shared/utility'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import Form from '../../UI/Form/Form'

class SignIn extends Component {
  state = {
    form: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@gmail.com'
        },
        value: '',
        label: 'Email',
        validation: {
          required: true,
          isEmail: true
        },
        helperText: 'Not a valid email',
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'password'
        },
        value: '',
        label: 'Password',
        validation: {
          required: true,
          minLength: 6
        },
        helperText: 'At least 6 characters',
        valid: false,
        touched: false
      }
    },
    formIsValid: false
  }

  signinHandler = () => {
    const formData = {}

    for (let elementID in this.state.form) {
      formData[elementID] = this.state.form[elementID].value
    }

    this.props.onSignin(formData.email, formData.password)
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updateFormElement = updateObject(this.state.form[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        this.state.form[inputIdentifier].validation
      ),
      touched: true
    })

    const updatedForm = updateObject(this.state.form, {
      [inputIdentifier]: updateFormElement
    })

    let formIsValid = true

    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid
    }

    this.setState({
      form: updatedForm,
      formIsValid: formIsValid
    })
  }

  closeErrorHandler = () => this.props.eraseError()

  render() {
    const formElements = []

    for (let key in this.state.form) {
      formElements.push({
        id: key,
        config: this.state.form[key]
      })
    }

    if (this.props.isAuthenticated) navigate(this.props.redirectPath)

    let error = null
    let errorMessage = null

    if (this.props.error) {
      if (this.props.error.code === 400) {
        error = true
        errorMessage = 'Email or Password is not correct.'
      }
    }

    let links = [
      <Link to="/pmp-exam-simulator-password-reset">Forgot password?</Link>,
      <Link to="/pmp-exam-simulator-sign-up">
        Don't have an account? Create one now
      </Link>
    ]

    return (
      <Form
        error={error}
        closeError={this.closeErrorHandler}
        errorMessage={errorMessage}
        title="Sign In"
        inputs={formElements}
        onSubmit={this.signinHandler}
        chageHandler={this.inputChangedHandler}
        spinner={this.props.loading}
        disabled={!this.state.formIsValid}
        links={links}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    redirectPath: state.auth.authRedirectPath,
    error: state.auth.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSignin: (email, password) => dispatch(actions.signin(email, password)),
    eraseError: () => dispatch(actions.authEraseError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
